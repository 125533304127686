<template>
  <Carousel
    :autoplay="5000"
    :wrap-around="true"
  >
    <Slide v-for="slide in this.slideImages.length" :key="slide">
      <div v-for="(s, index) in this.slideImages" :key="s" class="carousel__item" v-show="slide === index + 1">
        <HostedImg :imageName="s" workingFolder="Work" />
      </div>
    </Slide>

    <template #addons>
      <Navigation />
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
// @ is an alias to /src
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { clients } from "@/data/constants";
import HostedImg from "@/components/Controls/HostedImg.vue";

export default {
  name: "SlideDeck",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    HostedImg,
  },

  data() {
    return {
      client: "",
      constants: clients,
      slideImages: []
    };
  },
  created() {
    this.client = this.$store.getters.getClientInfo?.name;
    this.constants = clients;
    this.slideImages = this.$store.getters.getClientInfo?.picturesForSlide;
  },
};
</script>