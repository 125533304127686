<template>
  <div>
    <HeaderCommon />
    <h2>Some of our previous jobs</h2>
    <div class="imgcontainer three">
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h1-1.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h1-3.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h1-2.jpeg" />
    </div>
    <div class="description">
      <p>
        If you are ready to benefit from our exceptional services, we invite you
        to get in touch with us. You can reach us through the following contact
        details:
      </p>

      <p>
        <strong>Call Us:</strong> Feel free to give us a call at
        <a href="tel:+447765166242">07765 166 242</a>.
      </p>

      <p>
        <strong>Email Us:</strong> Alternatively, you can send us an email at
        <a href="mailto:peterhiltonexteriorcleaning@yahoo.co.uk"
          >peterhiltonexteriorcleaning@yahoo.co.uk</a
        >. We strive to respond promptly to all inquiries and service requests
        via email.
      </p>

      <p>
        We look forward to hearing from you and providing the high-quality
        exterior cleaning services you deserve. Thank you for considering us for
        your cleaning needs!
      </p>
    </div>
    <div class="imgcontainer four">
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h2-1.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h2-2.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h2-3.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h2-4.jpeg" />
    </div>
    <div class="imgcontainer two">
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h3-1.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h3-2.jpeg" />
    </div>
    <div class="imgcontainer five">
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h4-1.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h4-2.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h4-3.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h4-4.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h4-5.jpeg" />
    </div>
    <div class="imgcontainer four">
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h5-1.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h5-2.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h5-3.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h5-4.jpeg" />
    </div>
    <div class="imgcontainer four">
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h6-1.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h6-2.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h6-3.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h6-4.jpeg" />
    </div>
    <div class="imgcontainer four">
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h7-1.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h7-2.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h7-3.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h7-4.jpeg" />
    </div>
    <div class="imgcontainer three">
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h8-1.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h8-2.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h8-3.jpeg" />
    </div>
    <div class="imgcontainer three">
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h8-4.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h8-5.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h8-6.jpeg" />
    </div>
    <div class="imgcontainer three">
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h9-1.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h9-2.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h9-3.jpeg" />
    </div>
    <div class="imgcontainer three">
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h9-4.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h9-5.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h9-6.jpeg" />
    </div>
    <div class="imgcontainer two">
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h10-1.jpeg" />
      <img src="https://peterhiltonexteriorcleaning.co.uk/Work/h10-2.jpeg" />
    </div>
    <FooterCommon />
  </div>
</template>

<script>
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";
//import "vue-slider-component/theme/antd.css";

export default {
  name: "WorkView",
  components: {
    HeaderCommon,
    FooterCommon,
  },
};
</script>

<style lang="less" scoped>
.imgcontainer {
  width: 99%;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 3px solid #13cffee3;
  display: inline-block;
  img {
    margin-right: 1%;
  }
}

.description {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 3px solid #13cffee3;
  a {
    font-size: 16px;
    margin-right: 0px;
  }
}

.two {
  img {
    width: 48%;
  }
}
.three {
  img {
    width: 31%;
  }
}

.four {
  img {
    width: 24%;
  }
}

.five {
  img {
    width: 18%;
  }
}
</style>
