<template>
  <div class="home">
    <HeaderCommon />
    <HomePageContent v-if="client === constants.RRB" />
    <BlurbContent v-if="client === constants.SPAENVY" />
    <HomeBlurb v-if="client === constants.BBG" />
    <HomeBlurbP v-if="client === constants.PHEC" />
    <SlideDeck
      v-if="client === constants.SPAENVY || client === constants.PHEC"
    />
    <HomeBlurbPS v-if="client === constants.PHEC" />
    <HorizontalTicker v-if="client === constants.PHEC" />
    <AwardRack v-if="client === constants.SPAENVY" />
    <FeaturedProduct
      id="2"
      v-if="client === constants.SPAENVY || client === constants.BBG"
    />
    <FooterCommon />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";
import BlurbContent from "@/components/SpaEnvy/blurbContent.vue";
import HomeBlurb from "@/components/BBBG/HomeBlurb.vue";
import HomeBlurbP from "@/components/PHEC/HomeBlurb.vue";
import FeaturedProduct from "@/components/featuredProduct.vue";
import AwardRack from "@/components/SpaEnvy/awardRack.vue";
import "vue3-carousel/dist/carousel.css";
import HomePageContent from "@/components/DramaQueen/HomePageContent.vue";
import { clients } from "@/data/constants";
import SlideDeck from "@/components/Controls/SlideDeck.vue";
import HorizontalTicker from "@/components/Controls/HorizontalTicker.vue";
import HomeBlurbPS from "@/components/PHEC/HomeBlurbService.vue";

export default {
  name: "HomeView",
  components: {
    HeaderCommon,
    FooterCommon,
    HomePageContent,
    BlurbContent,
    HomeBlurb,
    HomeBlurbP,
    FeaturedProduct,
    AwardRack,
    SlideDeck,
    HorizontalTicker,
    HomeBlurbPS,
  },

  data() {
    return {
      client: "",
      constants: clients,
    };
  },
  created() {
    this.client = this.$store.getters.getClientInfo?.name;
    this.constants = clients;
  },
};
</script>
<style lang="less">
.carousel__item {
  width: 100%;
  background-color: #1496bc;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  background-color: #1496bc;
  border: 5px solid white;
}
.carousel__pagination-button {
  background-color: #1496bc;
}
</style>
