<template>
<div class="h-ticker">
    <div class="tickerItem" :key="tickerItem" v-for="tickerItem in this.tickerItems">{{ tickerItem }}</div>
</div>
</template>
<script>
export default {
name:"h-ticker",
data() {
    return {
      tickerItems: []
    };
  },
  created() {
    this.tickerItems = this.$store.getters.getClientInfo?.tickerItems;
  },
}
</script>
<style scoped>
.h-ticker{
    background-color: #13cffee3;
    color: #fff;
    padding: 15px;
    overflow-x: hidden;
    width: 100%;

    .tickerItem {
        display: inline-block;
        padding: 15px;
        font-weight: 600;
    }
}
</style>