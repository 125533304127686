<template>
  <div class="footer">
    <FooterSE v-if="footerType === 'SE'" />
    <FooterDQ v-if="footerType === 'DQ'" />
    <FooterMin v-if="footerType === 'Min'" />
  </div>
</template>

<script>
// @ is an alias to /src
import FooterSE from "@/components/Footers/FooterSE.vue";
import FooterDQ from "@/components/Footers/FooterDQ.vue";
import FooterMin from "@/components/Footers/FooterMinimalist.vue"

export default {
  name: "HomeView",
  components: {
    FooterSE,
    FooterDQ,
    FooterMin,
  },

  data() {
    return {
      footerType: ""
    };
  },
  created() {
    this.footerType = this.$store.getters.getClientInfo?.footerType;
  },
};
</script>
