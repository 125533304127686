<template>
  <div class="header">
    <HeaderSE v-if="headerType === 'SE'" />
    <HeaderDQ v-if="headerType === 'DQ'" />
    <HeaderMinimalist v-if="headerType === 'Min'" />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderSE from "@/components/Headers/HeaderSE.vue";
import HeaderDQ from "@/components/Headers/headerDQ.vue";
import HeaderMinimalist from "@/components/Headers/headerMinimalist.vue";

export default {
  name: "HomeView",
  components: {
    HeaderSE,
    HeaderDQ,
    HeaderMinimalist,
  },

  data() {
    return {
      headerType: "",
    };
  },
  created() {
    this.headerType = this.$store.getters.getClientInfo?.headerType;
  },
};
</script>
