<template>
  <div class="menu_area">
    <div class="wrapper">
      <div id="navigation">
        <div id="navbtn">menu</div>
        <ul class="dnav-menu" id="menu-nenu">
          <li
            v-for="menuItem in menuItems"
            :key="menuItem.route"
            class="site-nav__item site-nav--active"
          >
            <router-link :to="menuItem.route"> {{ menuItem.display }}</router-link>

          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MenuBar",

  data() {
    return {
      menuItems: [],
    };
  },
  created() {
    this.menuItems = this.$store.getters.getClientInfo?.menuItems
  },
};
</script>
