<template>
  <div class="ph-blurb">
    <h2>Welcome</h2>
    <p>
      Established as a premium service provider, Peter Hilton's External
      Cleaning is synonymous with professionalism, expertise, and dependability.
      Our years of industry experience have honed our skills, enabling us to
      guarantee top-notch results every time. Equipped with cutting-edge
      technology and an intricate understanding of various surface types, we
      take immense pride in restoring the luster to your floors, ensuring a safe
      and expedient process throughout.
    </p>
    <p>
      Our comprehensive array of services includes a wide spectrum of pressure
      washing solutions, catering to both commercial and domestic needs. Whether
      it's a large-scale project or a small household task, we have the
      proficiency and resources to handle it all. With our meticulous attention
      to detail and commitment to excellence, we assure you a flawlessly
      rejuvenated surface that emanates cleanliness.
    </p>
  </div>
</template>
<script>
export default {
  name: "PhBlurb",
};
</script>

<style lang="less" scoped>
.ph-blurb {
  width: 100%;
  padding: 5px 25px 25px 25px;
  border-top: 3px solid #13cffee3;
  margin-top: 15px;

  h2 {
    margin-top: 15px;
  }
}

img {
  width: 350px;
  height: 350px;
  margin: 1px;
  padding-bottom: 15px;
  border-bottom: 1px solid #13cffee3;
}
.text {
  h1 {
    border-bottom: 1px solid #fe1392;
  }
  display: inline-block;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  p {
    text-align: left;
    margin-left: 1px;
  }
}
@media screen and (max-device-width: 973px) {
  .ph-blurb {
    width: 100%;
    margin-left: 0px;
    padding-top: 0px;
    img {
      width: 35%;
      margin: 2%;
      display: inline-block;
      vertical-align: bottom;
    }
  }
}

@media screen and (max-device-width: 545px) {
  .ph-blurb {
    width: 100%;
    margin-left: 0px;
    padding-top: 0px;
    p {
      font-size: 14px;
    }
  }
  img {
    width: 31%;
    margin: 1px;
    display: inline-block;
    vertical-align: bottom;
  }
}
</style>
