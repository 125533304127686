export default [
  {
    review:
      "I would highly recommend Tina to everyone who wishes to be treated like a Queen! Had a really great first visit and I'm really looking forward to my next appointment. Tina is so welcoming and lovely, it was a real treat to be pampered. \n The best facial I've ever had, I felt so relaxed and my skin felt so refreshed afterwards I must say, the best manicure I've ever had.  3 weeks on and not a chip or any damage in sight!",
    reviewer: "Rosie",
    client: "RRB",
  },
  {
    review:
      "I have had a pedicure twice now from Rose and Rogue Beauty and both times the treatment has been outstanding. I felt relaxed and at ease throughout. Tina is passionate about what she does and really cares for her clients. I will definitely be booking in for more treatments! I highly recommend",
    reviewer: "Kimi",
    client: "RRB",
  },
  {
    review:
      "Absolutely loved my nails. Very professional, first class service. Highly recommend. Will come again.",
    reviewer: "Mel",
    client: "RRB",
  },
  {
    review:
      "I've been to Rose and Rogue Beauty a couple of times & I've never been disappointed. Tina is professional, kind & lovely to talk to. She knows exactly what her clients want & if I’m in doubt of what I want she’ll recommend a colour according to my needs. She has a great eye for detail & is a wonderful beautician. Would 100% recommend.",
    reviewer: "Amarah",
    client: "RRB",
  },
  {
    review:
      "This store is local to me and it has a wide selection of products at reasonable prices. Managed to pick up some hard to find hair care items which I would normally need to travel to London for",
    reviewer: "Tamara Vasquez",
    client: "BBG",
    stars: 5
  },
  {
    review:
      "I recently visited this shop and I am absolutely delighted with my experience! The owner was friendly and knowledgeable and went above and beyond to make sure I had everything I needed.",
    reviewer: "Nikita Conner",
    client: "BBG",
    stars: 5
  },
  {
    review:
      "I was extremely happy with the service I recieved, my driveway has never looked so spotless, the boys came on time and ensured that everything was spotless before they left, will hire again",
    reviewer: "Jimmy Golden",
    client: "PHEC",
    stars: 5
  },
  {
    review:
      "Absolutely fantastic service, lovely lads, very professional, very respectful of my property. This is the second time I've had my roof & guttering cleared and cleaned by this company & I wouldn't go anywhere else, always fabulous results! I had my roof cleaned, my gutters cleared, including the taking the down pipes off and unblocked, a new one fitted because one was leaking against my brick work, my solar panels were cleeaned and pidgeon fencing put around them to keep the birds out. My conservatory roof cleaned and the guttering around that cleaned. Very pleased excellent work!",
    reviewer: "Tracy Laird-Coleman",
    client: "PHEC",
    stars: 5
  },
];
