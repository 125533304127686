import client from "../../../public/data/client";

export const clientsStore = {
  state: () => ({ clientInfo: null }),
  actions: {},
  mutations: {},
  getters: {
    getClientInfo(state) {
      if (state.clientInfo == null) {

        if(client === null || client === undefined || client === ""){
          console.error("Unable to read client config")
        }

        state.clientInfo = client;
      }
      return state.clientInfo;
    },
  },
};
