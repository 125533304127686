<template>
  <div class="blurb">
    <p>
      We take pride in offering a diverse array of cleaning solutions to ensure
      a pristine and hygienic environment for both residential and commercial
      spaces.
    </p>
    <p>
      We offer services like domestic cleaning, commercial cleaning, roof
      cleaning & much more. Have a look at our services
      <router-link to="/services">here</router-link>
    </p>
    <!-- TrustBox widget - Review Collector -->
    <div
      class="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="56278e9abfbbba0bdcd568bc"
      data-businessunit-id="6553f83aa5bcacf32ce2324f"
      data-style-height="52px"
      data-style-width="100%"
    >
      <a
        href="https://uk.trustpilot.com/review/www.peterhiltonexteriorcleaning.co.uk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- End TrustBox widget -->

    <div class="backlinks">
      <p>
        We can be found through these places
      </p>
      <a
        href="https://www.bark.com/en/gb/company/peter-hilton-exterior-cleaning/1Vvaq/"
        target="_blank"
        class="bark-widget"
        data-type="pro"
        data-id="1Vvaq"
        data-image="medium-navy"
        data-version="3.0"
        >Peter Hilton Exterior Cleaning</a
      >

      <a
        href="https://www.yell.com/biz/peter-hilton-exterior-cleaning-farnham-10726525/ "
        target="_blank"
        class="yell"
        ><img src="../../assets/Yell.png"
      /></a>
    </div>
  </div>
</template>

<style lang="less" scoped>
.blurb {
  width: 100%;
  padding: 5px 25px 25px 25px;
  p {
    a {
      font-size: 16px;
      margin-right: 0px;
    }
  }
}

.backlinks {
  a {
    display: inline-block !important;
    vertical-align: middle;
    margin-left: 5px;
  }
  .yell {
    img {
      width: 200px;
    }
  }
}
</style>
