<template>
  <div class="header">
    <div class="logoWrapper">
      <img
        src="../../assets/PHEC/Logo.jpeg"
        alt="Main logo"
        v-show="name == 'PHEC'"
      />
      <div class="smallcontact">
        <p>{{ email }}</p>
        <p class="phone">{{ telephone }}</p>
      </div>
    </div>
    <div class="menuWrapper">
      <MenuBar />
    </div>
  </div>
</template>
<script>
import MenuBar from "@/components/Controls/Menubar.vue";
export default {
  name: "HeaderDQ",
  components: {
    MenuBar,
  },
  data() {
    return {
      telephone: "",
      name: "",
      email: "",
    };
  },
  created() {
    this.name = this.$store.getters.getClientInfo?.name;
    this.logoPath = "../../assets/" + this.name + "/Logo.jpeg";
    this.telephone = this.$store.getters.getClientInfo?.telephoneNo;
    this.email = this.$store.getters.getClientInfo?.email;
  },
};
</script>
