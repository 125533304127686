<template>
  <div
    id="shopify-section-footer"
    class="shopify-section"
    data-shopify-editor-section='{"id":"footer","type":"footer","disabled":false}'
  >
    <footer
      class="site-footer small--text-center"
      role="contentinfo"
      data-section-id="footer"
      data-section-type="footer-section"
    >
      <div class="wrapper">
        <div class="grid-uniform">
          <div class="grid__item large--one-quarter">
            <div class="footer_logo">
              <img
                src="../../assets/logo_280x.webp"
                srcset="
                  ../../assets/logo_280x.webp    1x,
                  ../../assets/logo_280x@2x.webp
                "
                alt="logo"
              />
            </div>
            <div
              class="rte rich-text__text--"
              data-live-text-setting="section.footer.text"
            >
              <p>
                Scotland's premier hot tub retailer and service provider. Oasis
                Spa stockist.
              </p>
            </div>
          </div>
          <div class="grid__item large--one-half">
            <h4>For the best advice on the best hot tubs:</h4>
            <h4>call us on 07462907383</h4>
            <h4>email us info@spaenvy.online</h4>
            <h4 class="wah">
              <img
                class="wahicon"
                src="../../assets/986960_whatsapp_icon.png"
              />
              Whatsapp us
              <a class="weLink" href="https://wa.me/447462907383">here</a>
            </h4>
          </div>

          <div class="grid__item large--one-quarter large--text-right">
            <p class="site-footer__copyright-content">
              © 2022, <a href="/" title="">Spa Envy</a> All rights reserved.
            </p>
            <p class="site-footer__copyright-content">Vat Number: 356057296</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<style lang="less">
.wahicon {
  width: 20px;
  margin-right: 10px;
}

.text-center {
  text-align: center !important;
}

div:after,
ul:after,
ol:after {
  clear: both;
  line-height: 0;
  content: "";
  display: table;
}

.aligncenter.brdr-btm {
  background: url(https://cdn.shopify.com/s/files/1/0421/4515/3185/files/border-bottom.png?v=1622817405)
    no-repeat 50% 75%;
  padding: 0px 0 30px;
}

.news_letter p {
  font-family: "Playfair Display", serif;
  color: #000;
  font-weight: 400;
  font-style: italic;
}

.newsletter form {
  margin: 0 auto;
  max-width: 780px;
}
.site-footer {
  padding-bottom: 30px;
  padding-top: 30px;
}

.page-container,
.drawer__header-container {
  -webkit-transition: all 0.35s cubic-bezier(0.46, 0.01, 0.32, 1);
  -moz-transition: all 0.35s cubic-bezier(0.46, 0.01, 0.32, 1);
  -ms-transition: all 0.35s cubic-bezier(0.46, 0.01, 0.32, 1);
  -o-transition: all 0.35s cubic-bezier(0.46, 0.01, 0.32, 1);
  transition: all 0.35s cubic-bezier(0.46, 0.01, 0.32, 1);
}

.site-footer__payment-icons {
  margin-left: -7.5px;
}

.footer_logo {
  margin: 0 0 15px 0;
}

.footer_links ul {
  width: 100%;
}

.footer_links ul li {
  width: 100%;
  padding: 5px 0 0;
  font: 500 13px/20px "Montserrat", sans-serif;
  color: #646464;
}

.footer_links ul li a {
  font: 600 13px/20px "Montserrat", sans-serif;
  color: #646464;
  text-transform: capitalize;
  display: block;
  text-align: left;
}

@media screen and (min-width: 591px) {
  .site-footer__linklist {
    margin-bottom: 15px;
  }
}

.no-bullets {
  list-style: none outside;
  margin-left: 0;
}

.rte {
  margin-bottom: 15px;
}

img {
  vertical-align: middle;
  border: 0;
  max-width: 100%;
  height: auto;
}

.payment-icons {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: default;
  margin-bottom: 0;
}

.payment-icons .icon {
  width: 38px;
  height: 24px;
}

svg:not(:root) {
  overflow: hidden;
}

.payment-icons li {
  margin: 0 0 7.5px 7.5px;
  cursor: default;
}

.inline-list li {
  display: inline-block;
  margin-bottom: 0;
}

.visually-hidden {
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

/*TIMBER STUFF */

.grid,
.grid--rev,
.grid--full{
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: -15px;
}
@media screen and (min-width: 591px) {
  .grid,
  .grid--rev,
  .grid--full {
    margin-left: -30px;
  }
}

.grid__item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  min-height: 1px;
  padding-left: 15px;
  vertical-align: top;
  width: 100%;
}
@media screen and (min-width: 591px) {
  .grid__item {
    padding-left: 30px;
  }
}

.grid--small {
  margin-left: -10px;
}
.grid--small .grid__item {
  padding-left: 10px;
}

.one-whole {
  width: 100%;
}

/* Halves */
.one-half {
  width: 50%;
}

/* Thirds */
.one-third {
  width: 33.333%;
}

.two-thirds {
  width: 66.666%;
}

/* Quarters */
.one-quarter {
  width: 25%;
}

.two-quarters {
  width: 50%;
}

.three-quarters {
  width: 75%;
}

/* Fifths */
.one-fifth {
  width: 20%;
}

.two-fifths {
  width: 40%;
}

.three-fifths {
  width: 60%;
}

.four-fifths {
  width: 80%;
}

/* Sixths */
.one-sixth {
  width: 16.666%;
}

.two-sixths {
  width: 33.333%;
}

.three-sixths {
  width: 50%;
}

.four-sixths {
  width: 66.666%;
}

.five-sixths {
  width: 83.333%;
}

/* Eighths */
.one-eighth {
  width: 12.5%;
}

.two-eighths {
  width: 25%;
}

.three-eighths {
  width: 37.5%;
}

.four-eighths {
  width: 50%;
}

.five-eighths {
  width: 62.5%;
}

.six-eighths {
  width: 75%;
}

.seven-eighths {
  width: 87.5%;
}

/* Tenths */
.one-tenth {
  width: 10%;
}

.two-tenths {
  width: 20%;
}

.three-tenths {
  width: 30%;
}

.four-tenths {
  width: 40%;
}

.five-tenths {
  width: 50%;
}

.six-tenths {
  width: 60%;
}

.seven-tenths {
  width: 70%;
}

.eight-tenths {
  width: 80%;
}

.nine-tenths {
  width: 90%;
}

/* Twelfths */
.one-twelfth {
  width: 8.333%;
}

.two-twelfths {
  width: 16.666%;
}

.three-twelfths {
  width: 25%;
}

.four-twelfths {
  width: 33.333%;
}

.five-twelfths {
  width: 41.666%;
}

.six-twelfths {
  width: 50%;
}

.seven-twelfths {
  width: 58.333%;
}

.eight-twelfths {
  width: 66.666%;
}

.nine-twelfths {
  width: 75%;
}

.ten-twelfths {
  width: 83.333%;
}

.eleven-twelfths {
  width: 91.666%;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

/*================ Our responsive classes, if we have enabled them ================*/
@media only screen and (max-width: 590px) {
  /** Whole */
  .small--one-whole {
    width: 100%;
  }

  /* Halves */
  .small--one-half {
    width: 50%;
  }

  /* Thirds */
  .small--one-third {
    width: 33.333%;
  }

  .small--two-thirds {
    width: 66.666%;
  }

  /* Quarters */
  .small--one-quarter {
    width: 25%;
  }

  .small--two-quarters {
    width: 50%;
  }

  .small--three-quarters {
    width: 75%;
  }

  /* Fifths */
  .small--one-fifth {
    width: 20%;
  }

  .small--two-fifths {
    width: 40%;
  }

  .small--three-fifths {
    width: 60%;
  }

  .small--four-fifths {
    width: 80%;
  }

  /* Sixths */
  .small--one-sixth {
    width: 16.666%;
  }

  .small--two-sixths {
    width: 33.333%;
  }

  .small--three-sixths {
    width: 50%;
  }

  .small--four-sixths {
    width: 66.666%;
  }

  .small--five-sixths {
    width: 83.333%;
  }

  /* Eighths */
  .small--one-eighth {
    width: 12.5%;
  }

  .small--two-eighths {
    width: 25%;
  }

  .small--three-eighths {
    width: 37.5%;
  }

  .small--four-eighths {
    width: 50%;
  }

  .small--five-eighths {
    width: 62.5%;
  }

  .small--six-eighths {
    width: 75%;
  }

  .small--seven-eighths {
    width: 87.5%;
  }

  /* Tenths */
  .small--one-tenth {
    width: 10%;
  }

  .small--two-tenths {
    width: 20%;
  }

  .small--three-tenths {
    width: 30%;
  }

  .small--four-tenths {
    width: 40%;
  }

  .small--five-tenths {
    width: 50%;
  }

  .small--six-tenths {
    width: 60%;
  }

  .small--seven-tenths {
    width: 70%;
  }

  .small--eight-tenths {
    width: 80%;
  }

  .small--nine-tenths {
    width: 90%;
  }

  /* Twelfths */
  .small--one-twelfth {
    width: 8.333%;
  }

  .small--two-twelfths {
    width: 16.666%;
  }

  .small--three-twelfths {
    width: 25%;
  }

  .small--four-twelfths {
    width: 33.333%;
  }

  .small--five-twelfths {
    width: 41.666%;
  }

  .small--six-twelfths {
    width: 50%;
  }

  .small--seven-twelfths {
    width: 58.333%;
  }

  .small--eight-twelfths {
    width: 66.666%;
  }

  .small--nine-twelfths {
    width: 75%;
  }

  .small--ten-twelfths {
    width: 83.333%;
  }

  .small--eleven-twelfths {
    width: 91.666%;
  }

  .small--show {
    display: block !important;
  }

  .small--hide {
    display: none !important;
  }

  .small--text-left {
    text-align: left !important;
  }

  .small--text-right {
    text-align: right !important;
  }

  .small--text-center {
    text-align: center !important;
  }

  .small--left {
    float: left !important;
  }

  .small--right {
    float: right !important;
  }
}
@media only screen and (min-width: 591px) and (max-width: 768px) {
  /** Whole */
  .medium--one-whole {
    width: 100%;
  }

  /* Halves */
  .medium--one-half {
    width: 50%;
  }

  /* Thirds */
  .medium--one-third {
    width: 33.333%;
  }

  .medium--two-thirds {
    width: 66.666%;
  }

  /* Quarters */
  .medium--one-quarter {
    width: 25%;
  }

  .medium--two-quarters {
    width: 50%;
  }

  .medium--three-quarters {
    width: 75%;
  }

  /* Fifths */
  .medium--one-fifth {
    width: 20%;
  }

  .medium--two-fifths {
    width: 40%;
  }

  .medium--three-fifths {
    width: 60%;
  }

  .medium--four-fifths {
    width: 80%;
  }

  /* Sixths */
  .medium--one-sixth {
    width: 16.666%;
  }

  .medium--two-sixths {
    width: 33.333%;
  }

  .medium--three-sixths {
    width: 50%;
  }

  .medium--four-sixths {
    width: 66.666%;
  }

  .medium--five-sixths {
    width: 83.333%;
  }

  /* Eighths */
  .medium--one-eighth {
    width: 12.5%;
  }

  .medium--two-eighths {
    width: 25%;
  }

  .medium--three-eighths {
    width: 37.5%;
  }

  .medium--four-eighths {
    width: 50%;
  }

  .medium--five-eighths {
    width: 62.5%;
  }

  .medium--six-eighths {
    width: 75%;
  }

  .medium--seven-eighths {
    width: 87.5%;
  }

  /* Tenths */
  .medium--one-tenth {
    width: 10%;
  }

  .medium--two-tenths {
    width: 20%;
  }

  .medium--three-tenths {
    width: 30%;
  }

  .medium--four-tenths {
    width: 40%;
  }

  .medium--five-tenths {
    width: 50%;
  }

  .medium--six-tenths {
    width: 60%;
  }

  .medium--seven-tenths {
    width: 70%;
  }

  .medium--eight-tenths {
    width: 80%;
  }

  .medium--nine-tenths {
    width: 90%;
  }

  /* Twelfths */
  .medium--one-twelfth {
    width: 8.333%;
  }

  .medium--two-twelfths {
    width: 16.666%;
  }

  .medium--three-twelfths {
    width: 25%;
  }

  .medium--four-twelfths {
    width: 33.333%;
  }

  .medium--five-twelfths {
    width: 41.666%;
  }

  .medium--six-twelfths {
    width: 50%;
  }

  .medium--seven-twelfths {
    width: 58.333%;
  }

  .medium--eight-twelfths {
    width: 66.666%;
  }

  .medium--nine-twelfths {
    width: 75%;
  }

  .medium--ten-twelfths {
    width: 83.333%;
  }

  .medium--eleven-twelfths {
    width: 91.666%;
  }

  .medium--show {
    display: block !important;
  }

  .medium--hide {
    display: none !important;
  }

  .medium--text-left {
    text-align: left !important;
  }

  .medium--text-right {
    text-align: right !important;
  }

  .medium--text-center {
    text-align: center !important;
  }

  .medium--left {
    float: left !important;
  }

  .medium--right {
    float: right !important;
  }
}
@media only screen and (max-width: 768px) {
  /** Whole */
  .medium-down--one-whole {
    width: 100%;
  }

  /* Halves */
  .medium-down--one-half {
    width: 50%;
  }

  /* Thirds */
  .medium-down--one-third {
    width: 33.333%;
  }

  .medium-down--two-thirds {
    width: 66.666%;
  }

  /* Quarters */
  .medium-down--one-quarter {
    width: 25%;
  }

  .medium-down--two-quarters {
    width: 50%;
  }

  .medium-down--three-quarters {
    width: 75%;
  }

  /* Fifths */
  .medium-down--one-fifth {
    width: 20%;
  }

  .medium-down--two-fifths {
    width: 40%;
  }

  .medium-down--three-fifths {
    width: 60%;
  }

  .medium-down--four-fifths {
    width: 80%;
  }

  /* Sixths */
  .medium-down--one-sixth {
    width: 16.666%;
  }

  .medium-down--two-sixths {
    width: 33.333%;
  }

  .medium-down--three-sixths {
    width: 50%;
  }

  .medium-down--four-sixths {
    width: 66.666%;
  }

  .medium-down--five-sixths {
    width: 83.333%;
  }

  /* Eighths */
  .medium-down--one-eighth {
    width: 12.5%;
  }

  .medium-down--two-eighths {
    width: 25%;
  }

  .medium-down--three-eighths {
    width: 37.5%;
  }

  .medium-down--four-eighths {
    width: 50%;
  }

  .medium-down--five-eighths {
    width: 62.5%;
  }

  .medium-down--six-eighths {
    width: 75%;
  }

  .medium-down--seven-eighths {
    width: 87.5%;
  }

  /* Tenths */
  .medium-down--one-tenth {
    width: 10%;
  }

  .medium-down--two-tenths {
    width: 20%;
  }

  .medium-down--three-tenths {
    width: 30%;
  }

  .medium-down--four-tenths {
    width: 40%;
  }

  .medium-down--five-tenths {
    width: 50%;
  }

  .medium-down--six-tenths {
    width: 60%;
  }

  .medium-down--seven-tenths {
    width: 70%;
  }

  .medium-down--eight-tenths {
    width: 80%;
  }

  .medium-down--nine-tenths {
    width: 90%;
  }

  /* Twelfths */
  .medium-down--one-twelfth {
    width: 8.333%;
  }

  .medium-down--two-twelfths {
    width: 16.666%;
  }

  .medium-down--three-twelfths {
    width: 25%;
  }

  .medium-down--four-twelfths {
    width: 33.333%;
  }

  .medium-down--five-twelfths {
    width: 41.666%;
  }

  .medium-down--six-twelfths {
    width: 50%;
  }

  .medium-down--seven-twelfths {
    width: 58.333%;
  }

  .medium-down--eight-twelfths {
    width: 66.666%;
  }

  .medium-down--nine-twelfths {
    width: 75%;
  }

  .medium-down--ten-twelfths {
    width: 83.333%;
  }

  .medium-down--eleven-twelfths {
    width: 91.666%;
  }

  .medium-down--show {
    display: block !important;
  }

  .medium-down--hide {
    display: none !important;
  }

  .medium-down--text-left {
    text-align: left !important;
  }

  .medium-down--text-right {
    text-align: right !important;
  }

  .medium-down--text-center {
    text-align: center !important;
  }

  .medium-down--left {
    float: left !important;
  }

  .medium-down--right {
    float: right !important;
  }

}
@media only screen and (min-width: 769px) {
  /** Whole */
  .large--one-whole {
    width: 100%;
  }

  /* Halves */
  .large--one-half {
    width: 50%;
  }

  /* Thirds */
  .large--one-third {
    width: 33.333%;
  }

  .large--two-thirds {
    width: 66.666%;
  }

  /* Quarters */
  .large--one-quarter {
    width: 25%;
  }

  .large--two-quarters {
    width: 50%;
  }

  .large--three-quarters {
    width: 75%;
  }

  /* Fifths */
  .large--one-fifth {
    width: 20%;
  }

  .large--two-fifths {
    width: 40%;
  }

  .large--three-fifths {
    width: 60%;
  }

  .large--four-fifths {
    width: 80%;
  }

  /* Sixths */
  .large--one-sixth {
    width: 16.666%;
  }

  .large--two-sixths {
    width: 33.333%;
  }

  .large--three-sixths {
    width: 50%;
  }

  .large--four-sixths {
    width: 66.666%;
  }

  .large--five-sixths {
    width: 83.333%;
  }

  /* Eighths */
  .large--one-eighth {
    width: 12.5%;
  }

  .large--two-eighths {
    width: 25%;
  }

  .large--three-eighths {
    width: 37.5%;
  }

  .large--four-eighths {
    width: 50%;
  }

  .large--five-eighths {
    width: 62.5%;
  }

  .large--six-eighths {
    width: 75%;
  }

  .large--seven-eighths {
    width: 87.5%;
  }

  /* Tenths */
  .large--one-tenth {
    width: 10%;
  }

  .large--two-tenths {
    width: 20%;
  }

  .large--three-tenths {
    width: 30%;
  }

  .large--four-tenths {
    width: 40%;
  }

  .large--five-tenths {
    width: 50%;
  }

  .large--six-tenths {
    width: 60%;
  }

  .large--seven-tenths {
    width: 70%;
  }

  .large--eight-tenths {
    width: 80%;
  }

  .large--nine-tenths {
    width: 90%;
  }

  /* Twelfths */
  .large--one-twelfth {
    width: 8.333%;
  }

  .large--two-twelfths {
    width: 16.666%;
  }

  .large--three-twelfths {
    width: 25%;
  }

  .large--four-twelfths {
    width: 33.333%;
  }

  .large--five-twelfths {
    width: 41.666%;
  }

  .large--six-twelfths {
    width: 50%;
  }

  .large--seven-twelfths {
    width: 58.333%;
  }

  .large--eight-twelfths {
    width: 66.666%;
  }

  .large--nine-twelfths {
    width: 75%;
  }

  .large--ten-twelfths {
    width: 83.333%;
  }

  .large--eleven-twelfths {
    width: 91.666%;
  }

  .large--show {
    display: block !important;
  }

  .large--hide {
    display: none !important;
  }

  .large--text-left {
    text-align: left !important;
  }

  .large--text-right {
    text-align: right !important;
  }

  .large--text-center {
    text-align: center !important;
  }

  .large--left {
    float: left !important;
  }

  .large--right {
    float: right !important;
  }
}
@media only screen and (min-width: 1200px) {
  /** Whole */
  .xlarge--one-whole {
    width: 100%;
  }

  /* Halves */
  .xlarge--one-half {
    width: 50%;
  }

  /* Thirds */
  .xlarge--one-third {
    width: 33.333%;
  }

  .xlarge--two-thirds {
    width: 66.666%;
  }

  /* Quarters */
  .xlarge--one-quarter {
    width: 25%;
  }

  .xlarge--two-quarters {
    width: 50%;
  }

  .xlarge--three-quarters {
    width: 75%;
  }

  /* Fifths */
  .xlarge--one-fifth {
    width: 20%;
  }

  .xlarge--two-fifths {
    width: 40%;
  }

  .xlarge--three-fifths {
    width: 60%;
  }

  .xlarge--four-fifths {
    width: 80%;
  }

  /* Sixths */
  .xlarge--one-sixth {
    width: 16.666%;
  }

  .xlarge--two-sixths {
    width: 33.333%;
  }

  .xlarge--three-sixths {
    width: 50%;
  }

  .xlarge--four-sixths {
    width: 66.666%;
  }

  .xlarge--five-sixths {
    width: 83.333%;
  }

  /* Eighths */
  .xlarge--one-eighth {
    width: 12.5%;
  }

  .xlarge--two-eighths {
    width: 25%;
  }

  .xlarge--three-eighths {
    width: 37.5%;
  }

  .xlarge--four-eighths {
    width: 50%;
  }

  .xlarge--five-eighths {
    width: 62.5%;
  }

  .xlarge--six-eighths {
    width: 75%;
  }

  .xlarge--seven-eighths {
    width: 87.5%;
  }

  /* Tenths */
  .xlarge--one-tenth {
    width: 10%;
  }

  .xlarge--two-tenths {
    width: 20%;
  }

  .xlarge--three-tenths {
    width: 30%;
  }

  .xlarge--four-tenths {
    width: 40%;
  }

  .xlarge--five-tenths {
    width: 50%;
  }

  .xlarge--six-tenths {
    width: 60%;
  }

  .xlarge--seven-tenths {
    width: 70%;
  }

  .xlarge--eight-tenths {
    width: 80%;
  }

  .xlarge--nine-tenths {
    width: 90%;
  }

  /* Twelfths */
  .xlarge--one-twelfth {
    width: 8.333%;
  }

  .xlarge--two-twelfths {
    width: 16.666%;
  }

  .xlarge--three-twelfths {
    width: 25%;
  }

  .xlarge--four-twelfths {
    width: 33.333%;
  }

  .xlarge--five-twelfths {
    width: 41.666%;
  }

  .xlarge--six-twelfths {
    width: 50%;
  }

  .xlarge--seven-twelfths {
    width: 58.333%;
  }

  .xlarge--eight-twelfths {
    width: 66.666%;
  }

  .xlarge--nine-twelfths {
    width: 75%;
  }

  .xlarge--ten-twelfths {
    width: 83.333%;
  }

  .xlarge--eleven-twelfths {
    width: 91.666%;
  }

  .xlarge--show {
    display: block !important;
  }

  .xlarge--hide {
    display: none !important;
  }

  .xlarge--text-left {
    text-align: left !important;
  }

  .xlarge--text-right {
    text-align: right !important;
  }

  .xlarge--text-center {
    text-align: center !important;
  }

  .xlarge--left {
    float: left !important;
  }

  .xlarge--right {
    float: right !important;
  }

}
</style>
