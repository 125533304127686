export default {
    name: "PHEC",
    failureTitle: "Order not processed",
    failureText: `Your order has not reached our agents, please either call us on
  07462907383 or email us at info@spaenvy.co.uk and we can handle your
  order from there.`,
    successText: `Your order has been emailed out to our agents who will get in contact
  with you to discuss your order and payment. If you do not hear from our
  agents, please either call us on 07462907383 or email us at
  info@spaenvy.co.uk`,
    successTitle: "Order sucessful",
    title: "Peter Hilton Exterior Cleaning",
    metaDescription: "Domestic, Commercial cleaning jet washing specialists.",
    email: "peterhiltonexteriorcleaning@yahoo.co.uk",
    telephoneNo: "07765 166 242",
    socialMedia: {
      instagram: {
        display: "@peter_hilton_exterior_cleaning",
        link: "https://www.instagram.com/peter_hilton_exterior_cleaning/?igshid=NzZlODBkYWE4Ng%3D%3D&utm_source=qr",
      },
      facebook: {
        display: "Peter Hilton Exterior Cleaning",
        link: "https://www.facebook.com/people/Peter-Hilton-Exterior-Cleaning/100093026431807/",
      },
      tiktok : {
        display: "@peterhiltonexteriorclean",
        link: "https://www.tiktok.com/@peterhiltonexteriorclean?_t=8h9nbHthxdA&_r=1"
      }
    },
    reviewBlurb:
    "Please see what our customers have to say about us",
    pictureLocation: "https://peterhiltonexteriorcleaning.co.uk",
    pictureExtension: "jpeg",
    slideTimer: 3000, 
    picturesForSlide: [
      "t-1",
      "t-2",
      "h1-2",
      "h3-2",
      "h9-4"
    ],
    tickerItems: [
      "Locations include: ",
      "Aldershot",
      "Ascot",
      "Ash",
      "Bagshot",
      "Basingstoke",
      "Bordon",
      "Bracknell",
      "Camberley",
      "Church Crookham",
      "Churt",
      "Crowthorne",
      "Egham",
      "Farnbrough",
      "Farnham",
      "Feltham",
      "Fleet",
      "Guildford",
      "Haslemere",
      "Hindhead",
      "Lightwater",
      "Liphook",
      "Petersfield",
      "Sandhurst",
      "Staines",
      "Sunningdale",
      "Virginia Water",
      "Walton on Thames",
      "Weybridge",
      "Windsor",
      "Woking"

    ],
    usesCart: false,
    headerType: "Min",
    footerType: "Min",
    menuItems: [
      {
        display: "Home",
        route: "/"
      },
      {
        display: "Services",
        route: "/services"
      },
      {
        display: "Our work",
        route: "/work"
      },
      {
        display: "Reviews",
        route: "/reviews"
      },
    ]
  }