<template>
  <div>
    <div class="footer">
      <div class="followWrapper">
        <div class="line">
          <p>Email us {{ email }}</p>
          <p class="phone">Call us {{ telephone }}</p>
          <a :href="socialMedia.facebook.link">
            <font-awesome-icon
              icon="fa-brands fa-facebook"
              v-if="socialMedia.facebook != undefined"
            />
          </a>

          <a :href="socialMedia.instagram.link">
            <font-awesome-icon
              icon="fa-brands fa-instagram"
              v-if="socialMedia.instagram != undefined"
          /></a>
          <a
            class="tiktok"
            :href="socialMedia.tiktok.link"
            v-if="socialMedia.tiktok != undefined"
            ><img src="../../assets//tiktok.jpg"
          /></a>
        </div>
      </div>
      <div class="f-content">
        <p>
          © 2023, <a href="/" title="">{{ title }}</a> All rights reserved.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterMin",

  data() {
    return {
      title: "",
      name: "",
      socialMedia: null,
      telephone: "",
      email: "",
    };
  },
  created() {
    this.title = this.$store.getters.getClientInfo?.title;
    this.name = this.$store.getters.getClientInfo?.name;
    this.socialMedia = this.$store.getters.getClientInfo?.socialMedia;
    this.telephone = this.$store.getters.getClientInfo?.telephoneNo;
    this.email = this.$store.getters.getClientInfo?.email;
  },
};
</script>
