<template>
  <div>
    <HeaderCommon />
    <div class="service-list">
      <h2 class="title">Services</h2>
      <h3>Domestic Cleaning</h3>
      <p>
        Explore the wide range of domestic cleaning services we offer to
        transform your home into a pristine and inviting space:
      </p>
      <ul>
        <li>
          <strong>Hot and Cold Pressure Washing:</strong> Revitalize your
          outdoor surfaces with our advanced hot and cold pressure washing
          services. We use cutting-edge equipment to remove dirt, grime, and
          stains from various surfaces, leaving them looking fresh and renewed.
        </li>

        <li>
          <strong>Brick and Stone Cleaning:</strong>
          Preserve the timeless beauty of your brick and stone surfaces with our
          specialized cleaning techniques. We carefully remove accumulated dirt
          and stains, restoring the natural allure of these materials.
        </li>

        <li>
          <strong>Patio & Pathway Restoration:</strong>
          Transform your outdoor living spaces with our patio and pathway
          restoration services. We clean, repair, and rejuvenate these areas,
          ensuring they become vibrant and welcoming once again.
        </li>

        <li>
          <strong>Concrete Cleaning:</strong>
          Bring back the luster of your concrete surfaces with our expert
          cleaning methods. We eliminate tough stains, mold, and mildew, leaving
          your concrete areas looking clean and well-maintained.
        </li>

        <li>
          <strong>Driveway Restoration:</strong>
          Give your driveway a facelift with our restoration services. We
          address issues such as cracks, stains, and uneven surfaces, ensuring
          your driveway regains its functionality and aesthetic appeal.
        </li>

        <li>
          <strong>Decking Cleaning and Treating:</strong>
          Protect and enhance the beauty of your deck with our specialized
          cleaning and treating services. We remove dirt, mold, and weathering,
          and then treat the wood to ensure longevity and resilience against the
          elements.
        </li>

        <li>
          <strong>Patio & Driveway Sealing:</strong>
          Guard against future damage by opting for our sealing services. We
          apply high-quality sealants to your patio and driveway, providing a
          protective barrier against water, stains, and UV rays.
        </li>

        <li>
          <strong>Swimming Pool Cleaning:</strong>
          Ensure your swimming pool is a sparkling oasis with our professional
          cleaning services. We handle everything from debris removal to water
          treatment, leaving you with a pristine and inviting pool.
        </li>

        <li>
          <strong>Black Spot Removal:</strong>
          Banish unsightly black spots from your outdoor surfaces with our
          targeted removal techniques. We address the root causes to prevent
          their return.
        </li>

        <li>
          <strong>Graffiti, Paint & Stain Removal:</strong>
          Restore the integrity of your surfaces by entrusting us to expertly
          remove graffiti, paint, and stubborn stains. Our methods are safe and
          effective, ensuring minimal impact on the underlying materials.
        </li>

        <li>
          <strong>Roof Cleaning & Moss Removal:</strong>
          Protect the structural integrity of your roof by eliminating moss,
          algae, and other contaminants. Our roof cleaning services not only
          enhance curb appeal but also extend the lifespan of your roof.
        </li>

        <li>
          <strong>Render Cleaning:</strong>
          Preserve the visual appeal of your rendered surfaces with our
          specialized cleaning methods. We remove dirt, mold, and pollutants,
          ensuring your render looks as good as new.
        </li>

        <li>
          <strong>Gutter Clearance:</strong>
          Prevent water damage and blockages by availing our gutter clearance
          services. We remove debris and ensure your gutters function smoothly,
          protecting your home from potential issues.
        </li>

        <li>
          <strong>Tarmac Restoration:</strong>
          Bring back the rich, dark appearance of your tarmac surfaces with our
          restoration services. We address issues such as fading, cracks, and
          stains, revitalizing the overall look of your property.
        </li>

        <li>
          <strong>Delicate Stone Cleaning:</strong>
          We handle delicate stones with care. Our cleaning methods are gentle
          yet effective, ensuring the beauty and integrity of delicate stone
          surfaces are preserved.
        </li>
      </ul>
      <h3>Commercial Cleaning</h3>
      We offer everything with domestic cleaning however we offer these
      additional services
      <ul>
        <li>Graffiti paint and stain removal</li>
        <li>Gutter clearance</li>
        <li>Brickwork and stone cleaning</li>
        <li>Tennis court restoration</li>
        <li>Playgrounds</li>
        <li>Forecourts</li>
        <li>Car parks</li>
        <li>Hotels</li>
        <li>Petrol Stations</li>
        <li>Pavements</li>
        <li>Tarmac restoration</li>
        <li>Chewing gum removal</li>
        <li>Efflorescence removal</li>
      </ul>
      <h3>Roof Cleaning</h3>
      <ul>
        <li>
          <strong>Roof Scraping:</strong> Thorough removal of debris, moss, and
          unwanted materials from your roof.
        </li>
        <li>
          <strong>Small Roof Repairs:</strong> Addressing minor issues to
          maintain the integrity of your roof structure.
        </li>
        <li>
          <strong>150 Degrees Steam Cleaning:</strong> Utilizing
          high-temperature steam to effectively and safely clean your roof.
        </li>
        <li>
          <strong>Biocide Treatments:</strong> Applying treatments to prevent
          the growth of moss, algae, and other harmful organisms.
        </li>
        <li>
          <strong>Free Drone Surveys:</strong> Utilizing advanced drone
          technology to provide comprehensive and accurate surveys of your roof,
          ensuring a precise assessment of its condition.
        </li>
      </ul>
    </div>

    <FooterCommon />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";

export default {
  name: "HomeView",
  components: {
    HeaderCommon,
    FooterCommon,
  },
};
</script>
<style scoped lang="less">
.service-list {
  margin-left: 15px;
  margin-right: 15px;
  strong {
    color: #244685;
  }
  ul{
    li{
        list-style: none;
    }
  }
}
</style>
