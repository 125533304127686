<template>
    <img :src="pictureUrl" />
</template>
<script>

export default {
  computed: {
    pictureUrl() {
        var pictureUrl = this.$store.getters.getClientInfo?.pictureLocation;
        var pictureExtension = this.$store.getters.getClientInfo?.pictureExtension;
        return pictureUrl + "/"+this.workingFolder +"/"+this.imageName+"."+ pictureExtension;
      }
  },
  props: {
    workingFolder: String,
    imageName: String,
  },
};
</script>
